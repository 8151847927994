import { Options } from '@/components/molecules/iteration-details/CreateIteration/types'

import ShopwareIcon from '@/assets/svg-components/stacks/ShopwareIcon'
import ShopifyIcon from '@/assets/svg-components/stacks/ShopifyIcon'
import CommerceLayerIcon from '@/assets/svg-components/stacks/CommerceLayerIcon'
import SapCommerceIcon from '@/assets/svg-components/stacks/SapCommerceIcon'
import SalesforceCommerceCloudIcon from '@/assets/svg-components/stacks/SalesforceCommerceCloudIcon'
import VtexIcon from '@/assets/svg-components/stacks/VtexIcon'
import ContentfulIcon from '@/assets/svg-components/stacks/ContentfulIcon'
import SprykerIcon from '@/assets/svg-components/stacks/SprykerIcon'
import OrdercloudIcon from '@/assets/svg-components/stacks/OrdercloudIcon'
import MedusaIcon from '@/assets/svg-components/stacks/MedusaIcon'

export const USECASE_ID = {
  SHOPWARE: 'shopware',
  COMMERCE_LAYER: 'commercelayer',
  SHOPIFY_HYDROGEN: 'hydrogen',
  SAP_COMMERCE: 'sapcc',
  SALESFORCE_COMMERCE_CLOUD: 'salesforce-commerce-cloud',
  VTEX: 'vtex',
  CONTENTFUL: 'contentful',
  SPRYKER: 'spriker',
  ORDERCLOUD: 'ordercloud',
  MEDUSA: 'medusa',
}

export const stackTemplate = {
  [USECASE_ID.SHOPWARE]: {
    value: USECASE_ID.SHOPWARE,
    promptExtras: `We want:
1. Start a new Shopware instance
2. Adjust the branding to match prospect branding (theme colors, images, logo)
3. Delete all the existing content
4. Create 2 category of products
5. For each category add three products. The products must have description.`,
    usecaseId: USECASE_ID.SHOPWARE,
    label: 'Shopware',
    toolsets: 'shopware',
    projectId: '5y8dE4OgOz1qJFHiEmZK',
    icon: ShopwareIcon,
  },
  [USECASE_ID.COMMERCE_LAYER]: {
    value: USECASE_ID.COMMERCE_LAYER,
    usecaseId: USECASE_ID.COMMERCE_LAYER,
    toolsets: 'commercelayer',
    projectId: 'ilAr2DGfoAQIGfPtQJzr',
    label: 'Commerce Layer',
    icon: CommerceLayerIcon,
  },
  [USECASE_ID.SHOPIFY_HYDROGEN]: {
    value: USECASE_ID.SHOPIFY_HYDROGEN,
    usecaseId: USECASE_ID.SHOPIFY_HYDROGEN,
    label: 'Shopify Hydrogen',
    icon: ShopifyIcon,
    projectId: 'YzJUwR0iNPAxIUCOJ0cD',
    promptExtras: `Default scope.`,
  },
  [USECASE_ID.SAP_COMMERCE]: {
    value: USECASE_ID.SAP_COMMERCE,
    usecaseId: USECASE_ID.SAP_COMMERCE,
    label: 'SAP Commerce',
    icon: SapCommerceIcon,
    projectId: 'Nb1uTZshKEeW3kIJOkM4',
    promptExtras: `Default scope.`,
  },
  [USECASE_ID.SALESFORCE_COMMERCE_CLOUD]: {
    value: USECASE_ID.SALESFORCE_COMMERCE_CLOUD,
    label: 'Salesforce Commerce Cloud',
    icon: SalesforceCommerceCloudIcon,
  },
  [USECASE_ID.VTEX]: {
    value: USECASE_ID.VTEX,
    label: 'Vtex',
    icon: VtexIcon,
  },
  [USECASE_ID.CONTENTFUL]: {
    value: USECASE_ID.CONTENTFUL,
    label: 'Contentful',
    icon: ContentfulIcon,
  },
  [USECASE_ID.SPRYKER]: {
    value: USECASE_ID.SPRYKER,
    label: 'Spryker',
    icon: SprykerIcon,
  },
  [USECASE_ID.ORDERCLOUD]: {
    value: USECASE_ID.ORDERCLOUD,
    label: 'Ordercloud',
    icon: OrdercloudIcon,
  },
  [USECASE_ID.MEDUSA]: {
    value: USECASE_ID.MEDUSA,
    label: 'Medusa',
    icon: MedusaIcon,
  },
}

export const technologiesOptions: Options = [
  {
    value: USECASE_ID.SHOPWARE,
    label: stackTemplate[USECASE_ID.SHOPWARE].label,
    group: 'Commerce Engine',
  },
  {
    value: USECASE_ID.COMMERCE_LAYER,
    label: stackTemplate[USECASE_ID.COMMERCE_LAYER].label,
    group: 'Commerce Engine',
  },
  {
    value: USECASE_ID.SHOPIFY_HYDROGEN,
    label: stackTemplate[USECASE_ID.SHOPIFY_HYDROGEN].label,
    group: 'Commerce Engine',
  },
  {
    value: USECASE_ID.SAP_COMMERCE,
    label: stackTemplate[USECASE_ID.SAP_COMMERCE].label,
    group: 'Commerce Engine',
  },
  {
    value: USECASE_ID.MEDUSA,
    label: stackTemplate[USECASE_ID.MEDUSA].label,
    group: 'Commerce Engine',
    disabled: false,
  },
  {
    value: USECASE_ID.SALESFORCE_COMMERCE_CLOUD,
    label: stackTemplate[USECASE_ID.SALESFORCE_COMMERCE_CLOUD].label,
    group: 'Commerce Engine',
    disabled: true,
  },
  {
    value: USECASE_ID.VTEX,
    label: stackTemplate[USECASE_ID.VTEX].label,
    group: 'Commerce Engine',
    disabled: true,
  },
  {
    value: USECASE_ID.CONTENTFUL,
    label: stackTemplate[USECASE_ID.CONTENTFUL].label,
    group: 'Commerce Engine',
    disabled: true,
  },
  {
    value: USECASE_ID.SPRYKER,
    label: stackTemplate[USECASE_ID.SPRYKER].label,
    group: 'Commerce Engine',
    disabled: true,
  },
  {
    value: USECASE_ID.ORDERCLOUD,
    label: stackTemplate[USECASE_ID.ORDERCLOUD].label,
    group: 'Commerce Engine',
    disabled: true,
  },
]
export const projectTemplates: {
  key: string
  name: string
  description: string
  technologies: Options
  whatToBuild: string
  technicalRequirements: string
  prospectRequirements: string
}[] = [
  {
    key: 'demo-store',
    name: 'Demo Store',
    description: 'Around 10 products',
    technologies: [
      { value: 'shopify-hydrogen', label: 'Shopify Hydrogen', group: 'Commerce Engine' },
      { value: 'react', label: 'React', group: 'Frontend' },
      { value: 'contentful', label: 'Contentful', group: 'CMS' },
    ],
    whatToBuild: 'A small e-commerce store with basic product listing and checkout functionality.',
    technicalRequirements: 'Shopify API integration, responsive design, basic SEO',
    prospectRequirements: 'Easy to manage inventory, simple checkout process',
  },
  {
    key: 'complex-shop',
    name: 'Complex Shop',
    description: '5+ categories',
    technologies: [
      { value: 'angular', label: 'Angular', group: 'Frontend' },
      { value: 'commercetools', label: 'Commercetools', group: 'Commerce Engine' },
      { value: 'sanity', label: 'Sanity', group: 'CMS' },
    ],
    whatToBuild: 'A multi-category e-commerce platform with advanced search and filtering.',
    technicalRequirements:
      'Server-side rendering, GraphQL API, Stripe payment integration, advanced product filtering',
    prospectRequirements: 'Multiple product categories, user accounts, wish lists',
  },
  {
    key: 'enterprise',
    name: 'Enterprise',
    description: '3+ stores',
    technologies: [
      { value: 'vue', label: 'Vue', group: 'Frontend' },
      { value: 'shopware', label: 'Shopware', group: 'Commerce Engine' },
      { value: 'strapi', label: 'Strapi', group: 'CMS' },
    ],
    whatToBuild: 'A scalable multi-store e-commerce ecosystem with shared backend services.',
    technicalRequirements:
      'Microservices architecture, Kubernetes deployment, shared authentication service, data synchronization between stores',
    prospectRequirements:
      'Multiple storefronts, centralized inventory management, cross-store analytics',
  },
]
