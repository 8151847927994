import { Dialog } from '@/components/catalyst/dialog.jsx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createIterationForProject, createProjectFirebaseFunction } from '@/services/Firebase.js'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import { useToast } from '@/components/ui/use-toast.js'
import PropTypes from 'prop-types'
import { stackTemplate, technologiesOptions } from '@/const/stackTemplates.ts'
import { Check, Container, FileText, FolderOpen, MoveLeft } from 'lucide-react'
import { Label } from '@/components/ui/label'
import { Controller, useForm } from 'react-hook-form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { helpContentWithExamples } from '@/components/molecules/project-details/CreateProject/helpContent'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cx } from 'class-variance-authority'
import { zodResolver } from '@hookform/resolvers/zod'
import useStore from '@/stores/useStore.js'
import { Textarea } from '@/components/ui/textarea'
import { useNavigate } from 'react-router-dom'
import { SiGithub } from '@icons-pack/react-simple-icons'
import {
  ProjectData,
  projectSchema,
  RepositoryData,
  repositorySchema,
} from '@/components/molecules/project-details/CreateProject/schemas'

const START_GUNSLINGER_FOR_NEW_ITERATION = window?.location?.host?.startsWith('old.') ? false : true

export default function MCreateProjectDialog({
  organizationId,
  teamId,
  isOpen,
  onClose,
  onProjectCreated = () => {},
}: {
  organizationId?: string
  teamId?: string
  isOpen: boolean
  onClose: () => void
  onProjectCreated: () => void
}) {
  const configurationTemplates = useStore(state => state.configurationTemplates)

  const { toast } = useToast()

  const { response, createFn, error: creationError, isCreating, resetCreateFn } = useCreateProject()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    if (response) {
      toast({
        title: 'Project created successfully! 🎉',
      })
      onProjectCreated(response?.data || {})

      resetCreateFn()
      handleClose()
    }
    if (creationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating project 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating project:', creationError)
      resetCreateFn()
    }
  }, [response, creationError, toast, resetCreateFn, handleClose, onProjectCreated])

  const onSubmit = (projectData, configurationTemplate) => {
    let newPrompt = `Let's build a POC for a new prospect, ${projectData.project.prospectName}.
Their website is ${projectData.project.prospectWebsite}`
    if (stackTemplate[projectData?.project?.techStack]?.promptExtras != null) {
      newPrompt += `\n\n${stackTemplate[projectData.project?.techStack].promptExtras}`
    }
    const payload = {
      prompt: newPrompt,
      // organizationId: 'bJSnHTIFUGXOx1JZb4Ie',
      // teamId: 'PHlh6Zhx05J8mll5JqAc',
      organizationId: projectData.organizationId,
      teamId: projectData.teamId,
      usecaseFields: configurationTemplate?.iterationDefaultsTemplate?.usecase?.fields,
      formData: {
        name: projectData.project.name,
        projectConfiguration: projectData.project,
        repository: Object.entries(projectData.repository).map(([key, value]) => ({ key, value })),
        environment: Object.entries(projectData.environment).map(([key, value]) => {
          const field = configurationTemplate?.iterationDefaultsTemplate?.environment?.fields.find(
            f => f.key === key
          )
          const dontEncrypt = field?.dontEncrypt
          return { key, value, dontEncrypt }
        }),
      },
    }
    if (!isCreating) {
      // console.log({
      //   organizationId,
      //   formData: data,
      //   usecaseFields: selectedTemplate?.iterationDefaultsTemplate?.usecase?.fields,
      //   teamId: selectedTeamId,
      //   templateId: selectedTemplateId,
      // })
      createFn(payload)
    }
  }

  if (configurationTemplates == null) {
    return (
      <Dialog
        className="max-h-[90vh] overflow-hidden p-[0] "
        size="3xl"
        open={isOpen}
        onClose={onClose}
      >
        <div className="inline-flex h-[412px] w-full flex-col items-end justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
          <div className="inline-flex items-center justify-start gap-4 self-stretch">
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-4">
              <div className="flex h-[380px] flex-col items-center justify-center gap-8 self-stretch rounded-lg border border-stone-200 bg-white px-8 py-20 shadow">
                <div className="inline-flex h-[120px] w-[120px] items-center justify-center rounded-full border border-[#954ee2] bg-gradient-to-b from-[#954ee2] to-[#954ee2]" />
                <div className="flex h-[68px] flex-col items-center justify-start gap-2 self-stretch">
                  <div className="font-['PP Supply Sans'] text-center text-3xl font-normal leading-10 text-stone-900">
                    Loading initial data
                  </div>
                  <div className="self-stretch text-center font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                    It shouldn’t take more than a couple of minutes...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog
        className="max-h-[90vh] overflow-hidden p-[0] "
        size="4xl"
        open={isOpen}
        onClose={onClose}
        static={true}
        preventOutsideClick={true}
      >
        <MCreateProjectWizard
          onCreateProject={onSubmit}
          submittingInProgress={isCreating}
          configurationTemplates={configurationTemplates}
          organizationId={organizationId}
          teamId={teamId}
          handleCancel={handleClose}
        />
      </Dialog>
    </>
  )
}

MCreateProjectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProjectCreated: PropTypes.func,
}

function ProjectWizardTab({
  name,
  value,
  activeStep,
  isValid,
  Icon,
}: {
  name: string
  value: string
  activeStep: string
  isValid: boolean
  Icon: any
}) {
  const selected = activeStep === value
  return (
    <TabsTrigger
      value={value}
      disabled={activeStep !== value && !isValid}
      className={cx(
        // Base classes
        'inline-flex h-10 w-full items-center justify-between ',
        'px-4 py-2.5',

        // Conditional background
        selected ? 'bg-stone-200 text-stone-900' : 'bg-transparent'
      )}
    >
      {/*<div className="inline-flex h-10 w-full items-center justify-start gap-3 self-stretch rounded-lg px-4 py-2.5">*/}
      <div className="flex items-center justify-start gap-3">
        <div className="relative flex h-4 w-4 items-center justify-center">
          <Icon className={cx(selected ? 'text-orange-500' : 'text-stone-500')} />
        </div>
        <div className=" shrink grow basis-0 font-['Inter'] text-sm font-medium leading-tight ">
          {name}
        </div>
      </div>

      <div className="relative flex h-4 w-4 items-center justify-center">
        {isValid && <Check />}
      </div>
      {/*</div>*/}
    </TabsTrigger>
  )
}

export type EnvironmentData = {
  envType: string
  configuration: string
}

export type WizardData = {
  project: ProjectData | null
  repository: RepositoryData | null
  environment: EnvironmentData | null
}

export type WizardStep = 'project' | 'repository' | 'environment' | 'summary'

export type CompletedSteps = {
  project: boolean
  repository: boolean
  environment: boolean
  summary: boolean
}

export type ProjectFormProps = {
  onSubmit: (data: ProjectData) => void
  initialData?: Partial<ProjectData>
  onSelectField: (field: string) => void
  organizationId: string
  teamId: string
}

interface RepositoryFormProps {
  onSubmit: (data: RepositoryData) => void
  onBack: () => void // New prop for handling back navigation
  initialData?: Partial<RepositoryData>
  onSelectField?: (fieldName: keyof RepositoryData) => void
}

export type EnvironmentFormProps = {
  onSubmit: (data: EnvironmentData) => void
  onBack: () => void
  initialData?: Partial<EnvironmentData>
  selectedConfigurationTemplate?: any
}

export type EnvironmentOption = {
  value: string
  label: string
}

export type SummaryFormProps = {
  onSubmit: () => void
  onBack: () => void
  projectData: ProjectData | null
  repositoryData: RepositoryData | null
  environmentData: EnvironmentData | null
}

const EMPTY_DATA = {
  project: {},
  repository: {},
  environment: {},
}

const ALL_VALID = {
  project: true,
  repository: true,
  environment: true,
  summary: true,
}

const INITIAL_VALID = {
  project: false,
  repository: false,
  environment: false,
  summary: false,
}

function MCreateProjectWizard({
  onCreateProject,
  submittingInProgress,
  configurationTemplates,
  organizationId,
  teamId,
  handleCancel,
}) {
  const [selectedField, setSelectedField] = useState<string | null>(null)

  const [activeStep, setActiveStep] = useState<WizardStep>('project')
  const [completedSteps, setCompletedSteps] = useState<CompletedSteps>(INITIAL_VALID)
  const [formData, setFormData] = useState<WizardData>(EMPTY_DATA)

  const selectedConfigurationTemplate = useMemo(() => {
    if (formData.project?.techStack) {
      return configurationTemplates?.find(template => template.id === formData.project?.techStack)
    }
    return null
  }, [configurationTemplates, formData.project?.techStack])

  const content = useMemo(() => {
    if (activeStep === 'summary') {
      return null
    }
    if (!selectedField) {
      return helpContentWithExamples['default']
    } else if (helpContentWithExamples[selectedField]) {
      return helpContentWithExamples[selectedField]
    } else {
      const fields = selectedConfigurationTemplate?.iterationDefaultsTemplate.environment.fields
      const foundMatching = fields?.find(f => f.key === selectedField)
      if (foundMatching) {
        return { text: foundMatching.description }
      }
    }
    return helpContentWithExamples['default']
  }, [selectedField, helpContentWithExamples, selectedConfigurationTemplate, activeStep])

  const handleStepChange = (step: WizardStep) => {
    if (!completedSteps[step] && step !== activeStep) {
      const steps: WizardStep[] = ['project', 'repository', 'environment', 'summary']
      const currentIndex = steps.indexOf(activeStep)
      const targetIndex = steps.indexOf(step)

      if (targetIndex > currentIndex && !completedSteps[steps[currentIndex]]) {
        return
      }
    }

    setActiveStep(step)
  }

  const handleProjectSubmit = async (data: ProjectData) => {
    try {
      if (formData.project?.techStack && data.techStack !== formData.project?.techStack) {
        setFormData(prev => ({ ...prev, repository: {}, environment: {} }))
      }
      // await new Promise(resolve => setTimeout(resolve, 500))
      const { organizationId, teamId } = data
      setFormData(prev => ({ ...prev, organizationId, teamId, project: data }))
      setCompletedSteps(prev => ({ ...prev, project: true }))
      setActiveStep('repository')
    } catch (error) {
      console.error('Error saving project data:', error)
    }
  }

  const handleRepositorySubmit = async (data: RepositoryData) => {
    try {
      // await new Promise(resolve => setTimeout(resolve, 500))
      setFormData(prev => ({ ...prev, repository: data }))
      setCompletedSteps(prev => ({ ...prev, repository: true }))
      setActiveStep('environment')
    } catch (error) {
      console.error('Error saving repository data:', error)
    }
  }

  const handleEnvironmentSubmit = async (data: EnvironmentData) => {
    try {
      // await new Promise(resolve => setTimeout(resolve, 500))
      setFormData(prev => ({ ...prev, environment: data }))
      setCompletedSteps(prev => ({ ...prev, environment: true }))
      setActiveStep('summary')
    } catch (error) {
      console.error('Error saving environment data:', error)
    }
  }

  const handleBuildPoc = async () => {
    onCreateProject(formData, selectedConfigurationTemplate)
  }

  const handleBack = () => {
    switch (activeStep) {
      case 'repository':
        setActiveStep('project')
        break
      case 'environment':
        setActiveStep('repository')
        break
      case 'summary':
        setActiveStep('environment')
        break
    }
  }

  if (submittingInProgress) {
    return (
      <div className="inline-flex h-[412px] w-full flex-col items-end justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
        <div className="inline-flex items-center justify-start gap-4 self-stretch">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-4">
            <div className="flex h-[380px] flex-col items-center justify-center gap-8 self-stretch rounded-lg border border-stone-200 bg-white px-8 py-20 shadow">
              <div className="inline-flex h-[120px] w-[120px] items-center justify-center rounded-full border border-[#954ee2] bg-gradient-to-b from-[#954ee2] to-[#954ee2]" />
              <div className="flex h-[68px] flex-col items-center justify-start gap-2 self-stretch">
                <div className="font-['PP Supply Sans'] text-center text-3xl font-normal leading-10 text-stone-900">
                  We’re building {formData.project.name} PoC
                </div>
                <div className="self-stretch text-center font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                  It shouldn’t take more than a couple of minutes...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Tabs
      value={activeStep}
      onValueChange={handleStepChange}
      className=" w-full"
      orientation="vertical"
    >
      <div className="inline-flex w-full flex-col items-center justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
        <div className="inline-flex min-h-96 items-center justify-between gap-4 self-stretch">
          <div className="inline-flex  w-[204px] flex-col items-start justify-between self-stretch pb-14">
            <div className="flex flex-col items-start justify-start gap-4 self-stretch">
              <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
                New Project
              </div>

              <TabsList className="flex flex-col items-start justify-start gap-1 self-stretch rounded-md">
                <ProjectWizardTab
                  name="Project"
                  value="project"
                  activeStep={activeStep}
                  isValid={!!completedSteps.project}
                  Icon={FolderOpen}
                />
                <ProjectWizardTab
                  name="Repository"
                  value="repository"
                  activeStep={activeStep}
                  isValid={!!completedSteps.repository}
                  Icon={SiGithub}
                />
                <ProjectWizardTab
                  name="Environment"
                  value="environment"
                  activeStep={activeStep}
                  isValid={!!completedSteps.environment}
                  Icon={Container}
                />
                <ProjectWizardTab
                  name="Summary"
                  value="summary"
                  activeStep={activeStep}
                  isValid={!!completedSteps.summary}
                  Icon={FileText}
                />
              </TabsList>
            </div>
            <div className=" self-stretch font-['Inter'] text-sm font-normal leading-tight text-stone-500">
              {content?.text}
            </div>
          </div>
          <div className="flex-1">
            <TabsContent value="project" className="mt-0 w-full data-[state=inactive]:hidden">
              <ProjectForm
                onSubmit={handleProjectSubmit}
                initialData={formData.project}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                organizationId={organizationId}
                teamId={teamId}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="repository" className="mt-0 w-full data-[state=inactive]:hidden">
              <RepositoryForm
                onSubmit={handleRepositorySubmit}
                onBack={handleBack}
                initialData={formData.repository}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="environment" className="mt-0 w-full data-[state=inactive]:hidden">
              <EnvironmentForm
                onSubmit={handleEnvironmentSubmit}
                onBack={handleBack}
                initialData={formData.environment}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                selectedConfigurationTemplate={selectedConfigurationTemplate}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="summary" className="mt-0 w-full data-[state=inactive]:hidden">
              <SummaryForm
                onSubmit={handleBuildPoc}
                onBack={handleBack}
                formData={formData}
                submittingInProgress={submittingInProgress}
                handleCancel={handleCancel}
              />
            </TabsContent>
          </div>
        </div>
      </div>
    </Tabs>
  )
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  organizationId,
  teamId,
  handleCancel,
}) => {
  const getTeamsByOrgId = useStore(state => state.getTeamsByOrgId)
  const organizationsOptions = useStore(state =>
    state.organizations.map(org => ({ value: org.id, label: org.name }))
  )
  const initialSelectedOrganizationId =
    initialData?.organizationId ??
    organizationId ??
    (organizationsOptions.length === 1 ? organizationsOptions[0].value : '')
  const [teamOptions, setTeamOptions] = useState(() =>
    getTeamsByOrgId(initialSelectedOrganizationId).map(org => ({ value: org.id, label: org.name }))
  )
  const initialSelectedTeamId =
    initialData?.teamId ?? teamId ?? (teamOptions.length === 1 ? teamOptions[0].value : '')

  const showOrgAndTeamFields = organizationsOptions.length > 1 || teamOptions.length > 1

  const form = useForm<ProjectData>({
    resolver: zodResolver(projectSchema),
    defaultValues: {
      name: initialData?.name || '',
      prospectName: initialData?.prospectName || '',
      prospectWebsite: initialData?.prospectWebsite || '',
      techStack: initialData?.techStack || '',
      organizationId: initialSelectedOrganizationId,
      teamId: initialSelectedTeamId,
    },
    mode: 'onChange',
  })

  const organizationIdField = form.watch('organizationId')
  const teamIdField = form.watch('teamId')

  useEffect(() => {
    setTeamOptions(
      getTeamsByOrgId(organizationIdField).map(team => ({ value: team.id, label: team.name }))
    )
  }, [organizationIdField, getTeamsByOrgId])

  React.useEffect(() => {
    form.setFocus('name')
  }, [form.setFocus])

  // const shouldShowTeamField =
  //   teamOptions.length > 1 ||
  //   !teamIdField ||
  //   (teamIdField && !teamOptions.map(o => o.value).includes(teamIdField))

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex w-full flex-col items-start justify-start gap-6 self-stretch rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Project details
        </div>
        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Project</label>
          <Input
            {...form.register('name')}
            placeholder="Project name"
            className="my-custom-focus"
            onFocus={() => onSelectField('name')}
          />
          {form.formState.errors.name && (
            <p className="text-sm text-red-500">{form.formState.errors.name.message}</p>
          )}
        </div>

        {showOrgAndTeamFields && (
          <Controller
            name="organizationId"
            control={form.control}
            onFocus={() => onSelectField('organizationId')}
            render={({ field, fieldState: { error } }) => (
              <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
                <Label htmlFor="technologies" className="text-sm font-medium text-gray-700">
                  Organization
                </Label>
                <Select
                  onFocus={() => onSelectField('organizationId')}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
                >
                  <SelectTrigger
                    onClick={e => e.stopPropagation()}
                    onFocus={() => onSelectField('organizationId')}
                    // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                    tabIndex={0}
                    className="my-custom-focus mt-1"
                  >
                    <SelectValue placeholder="Select an organization..." />
                  </SelectTrigger>
                  <SelectContent className="w-full" onFocus={() => onSelectField('organizationId')}>
                    {organizationsOptions.map(option => {
                      return (
                        <SelectItem key={option.value} value={option.value}>
                          <div className="flex items-center justify-start gap-3">
                            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                              {option.label}
                            </div>
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>

                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
        )}

        {showOrgAndTeamFields && (
          <Controller
            name="teamId"
            control={form.control}
            onFocus={() => onSelectField('teamId')}
            render={({ field, fieldState: { error } }) => (
              <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
                <Label htmlFor="technologies" className="text-sm font-medium text-gray-700">
                  Team
                </Label>
                <Select
                  onFocus={() => onSelectField('teamId')}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
                >
                  <SelectTrigger
                    onClick={e => e.stopPropagation()}
                    onFocus={() => onSelectField('teamId')}
                    // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                    tabIndex={0}
                    className="my-custom-focus mt-1"
                  >
                    <SelectValue placeholder="Select a team..." />
                  </SelectTrigger>
                  <SelectContent className="w-full" onFocus={() => onSelectField('teamId')}>
                    {teamOptions.map(option => {
                      return (
                        <SelectItem key={option.value} value={option.value}>
                          <div className="flex items-center justify-start gap-3">
                            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                              {option.label}
                            </div>
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>

                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
        )}

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Prospect</label>
          <Input
            {...form.register('prospectName')}
            placeholder="Prospect"
            className="my-custom-focus"
            onFocus={() => onSelectField('prospectName')}
          />
          {form.formState.errors.prospectName && (
            <p className="text-sm text-red-500">{form.formState.errors.prospectName.message}</p>
          )}
        </div>
        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Prospect website</label>
          <Input
            {...form.register('prospectWebsite')}
            placeholder="https://www.example.com"
            type="text"
            className="my-custom-focus"
            onFocus={() => onSelectField('prospectWebsite')}
          />
          {form.formState.errors.prospectWebsite && (
            <p className="text-sm text-red-500">{form.formState.errors.prospectWebsite.message}</p>
          )}
        </div>

        <Controller
          name="techStack"
          control={form.control}
          onFocus={() => onSelectField('techStack')}
          render={({ field, fieldState: { error } }) => (
            <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
              <Label htmlFor="technologies" className="text-sm font-medium text-gray-700">
                Tech stack
              </Label>
              <Select
                onFocus={() => onSelectField('techStack')}
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
              >
                <SelectTrigger
                  onFocus={() => onSelectField('techStack')}
                  // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                  tabIndex={0}
                  className="my-custom-focus mt-1"
                >
                  <SelectValue placeholder="Select a stack..." />
                </SelectTrigger>
                <SelectContent className="w-full" onFocus={() => onSelectField('technologies')}>
                  {technologiesOptions.map(option => {
                    const stack = option.value
                    const TechnologyIcon = stackTemplate[stack]?.icon
                    return (
                      <SelectItem
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                      >
                        <div className="flex items-center justify-start gap-3">
                          <div className="flex h-4 w-4 items-center justify-center">
                            {TechnologyIcon && <TechnologyIcon />}
                          </div>
                          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                            {option.label}
                          </div>
                        </div>
                      </SelectItem>
                    )
                  })}
                </SelectContent>
              </Select>

              {error && <p className="text-red-500">{error.message}</p>}
            </div>
          )}
        />
      </div>

      <div className="inline-flex items-start justify-between self-stretch">
        <div
          className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
          onClick={handleCancel}
        >
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
            Cancel
          </div>
        </div>

        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="w-full bg-black  text-white"
          >
            Next
            {/*<ArrowRight className="ml-2 h-4 w-4" />*/}
          </Button>
        </div>
      </div>
    </form>
  )
}

const RepositoryForm: React.FC<RepositoryFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  onBack,
  handleCancel,
}) => {
  const form = useForm<RepositoryData>({
    resolver: zodResolver(repositorySchema),
    defaultValues: {
      repoURI: initialData?.repoURI || undefined,
      startCommitHash: initialData?.startCommitHash || undefined,
      githubKey: initialData?.githubKey || undefined,
    },
    mode: 'onChange',
  })

  React.useEffect(() => {
    form.setFocus('repoURI')
  }, [form.setFocus])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex w-full flex-col items-start justify-start gap-6 self-stretch rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Repository setup
        </div>

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <Label className="text-sm font-medium">Repository URL</Label>
          <Input
            {...form.register('repoURI')}
            placeholder="git@github.com:org/repo.git"
            className="my-custom-focus"
            onFocus={() => onSelectField?.('repoURI')}
          />
          {form.formState.errors.repoURI && (
            <p className="text-sm text-red-500">{form.formState.errors.repoURI.message}</p>
          )}
        </div>

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <Label className="text-sm font-medium">Start commit hash</Label>
          <Input
            {...form.register('startCommitHash')}
            placeholder="1234567890abcdef"
            className="my-custom-focus"
            onFocus={() => onSelectField?.('startCommitHash')}
          />
          {form.formState.errors.startCommitHash && (
            <p className="text-sm text-red-500">{form.formState.errors.startCommitHash.message}</p>
          )}
        </div>

        <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
          <Label className="text-sm font-medium">GitHub PAT (Personal Access Token)</Label>
          <Input
            {...form.register('githubKey')}
            type="password"
            placeholder="Enter your GitHub key"
            className="my-custom-focus"
            onFocus={() => onSelectField?.('githubKey')}
          />
          {form.formState.errors.githubKey && (
            <p className="text-sm text-red-500">{form.formState.errors.githubKey.message}</p>
          )}
        </div>
      </div>

      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex">
          <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center gap-2 rounded-md px-4 py-2"
            onClick={onBack}
          >
            <div className="relative flex h-4 w-4 items-center">
              <MoveLeft />
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
            onClick={handleCancel}
          >
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              Cancel
            </div>
          </div>
        </div>
        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="w-full bg-black text-white"
          >
            Next
          </Button>
        </div>
      </div>
    </form>
  )
}

const EnvironmentForm: React.FC<EnvironmentFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  onBack,
  selectedConfigurationTemplate,
  handleCancel,
}) => {
  const fields = selectedConfigurationTemplate?.iterationDefaultsTemplate?.environment?.fields
  const defaultValues = useMemo(() => {
    return fields?.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]:
          initialData && initialData[field.key]
            ? initialData[field.key]
            : field.default_value ?? '',
      }),
      {}
    )
  }, [selectedConfigurationTemplate?.iterationDefaultsTemplate?.environment?.fields])

  const form = useForm({
    defaultValues: defaultValues,
    mode: 'all',
  })
  React.useEffect(() => {
    if (fields[0]) {
      form.setFocus(fields[0].key)
    }
  }, [form.setFocus])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex h-[576px] w-full flex-col items-start justify-start gap-8 self-stretch overflow-y-scroll rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Environment setup
        </div>

        {fields.length === 0 && <div>No environment setup required for this stack.</div>}

        {fields.map(field => {
          return (
            <div
              className={cx('flex flex-col items-start justify-start gap-2 self-stretch')}
              key={field.key}
            >
              <Label className="self-stretch font-['Inter'] text-sm font-medium leading-[14px] text-stone-900">
                {field.key}
              </Label>
              {/*<div className="font-['DM Mono'] self-stretch text-xs font-normal uppercase leading-3 text-stone-500">*/}
              {/*  {field.key}*/}
              {/*</div>*/}
              {field.type === 'text-long' ? (
                <Textarea
                  {...form.register(field.key, {
                    required: field.required ? 'Field is required' : false,
                  })}
                  placeholder={field.placeholder}
                  className={cx(
                    'my-custom-focus',
                    field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                  )}
                  onFocus={() => onSelectField?.(field.key)}
                  invalid={!!form.formState.errors[field.key]}
                />
              ) : (
                <Input
                  {...form.register(field.key, {
                    required: field.required ? 'Field is required' : false,
                  })}
                  placeholder={field.placeholder}
                  className={cx(
                    'my-custom-focus',
                    field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                  )}
                  onFocus={() => onSelectField?.(field.key)}
                  invalid={!!form.formState.errors[field.key]}
                />
              )}

              {form.formState.errors[field.key] && (
                <p className="text-sm text-red-500">{form.formState.errors[field.key]?.message}</p>
              )}
            </div>
          )
        })}
      </div>

      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex">
          <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center gap-2 rounded-md px-4 py-2"
            onClick={onBack}
          >
            <div className="relative flex h-4 w-4 items-center">
              <MoveLeft />
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
            onClick={handleCancel}
          >
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              Cancel
            </div>
          </div>
        </div>
        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            className="w-full bg-black text-white"
          >
            Next
          </Button>
        </div>
      </div>
    </form>
  )
}

function SummaryForm({ onSubmit, onBack, formData, submittingInProgress, handleCancel }) {
  let newPrompt = `Let's build a POC for a new prospect, ${formData.project.prospectName}.
Their website is ${formData.project.prospectWebsite}`
  if (stackTemplate[formData.project?.techStack]?.promptExtras != null) {
    newPrompt += `\n\n${stackTemplate[formData.project?.techStack].promptExtras}`
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="inline-flex h-[576px] flex-col items-start justify-start gap-8 overflow-y-scroll rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Proof of Concept for {formData.project.prospectName}.
        </div>
        <div className="flex flex-col items-start justify-start self-stretch">
          <div className="inline-flex items-start justify-start gap-2 self-stretch border-b border-t border-stone-200 py-3">
            <div className="w-32 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Prospect:
            </div>
            <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-900">
              {formData.project.prospectName}
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-2 self-stretch border-b border-t border-stone-200 py-3">
            <div className="w-32 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Website:
            </div>
            <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-900">
              {formData.project.prospectWebsite}
            </div>
          </div>
          <div className="inline-flex items-start justify-start gap-2 self-stretch border-b border-t border-stone-200 py-3">
            <div className="w-32 font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Date:
            </div>
            <div className="font-['Inter'] text-sm font-normal leading-tight text-stone-900">
              {new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-4 self-stretch">
          <div className="self-stretch font-['Inter'] text-base font-medium leading-normal text-stone-900">
            Objective
          </div>
          <div className="self-stretch whitespace-pre-wrap font-['Inter'] text-base font-normal leading-normal text-stone-900">
            {newPrompt}
          </div>
        </div>
        <div className="flex h-16 flex-col items-start justify-start gap-4 self-stretch">
          <div className="self-stretch font-['Inter'] text-base font-medium leading-normal text-stone-900">
            Technology
          </div>
          <div className="flex h-6 flex-col items-start justify-start gap-1 self-stretch">
            <div className="inline-flex items-start justify-start gap-1.5 self-stretch">
              <div className="flex items-center justify-start gap-1 rounded-md bg-stone-100 px-1.5">
                <div className="flex h-4 w-4 items-center justify-center px-px">
                  <div className="relative flex h-4 w-3.5 flex-col items-start justify-start" />
                </div>
                <div className="font-['Inter'] text-base font-normal leading-normal text-stone-900">
                  {stackTemplate[formData.project?.techStack]?.label}
                </div>
              </div>
              <div className="font-['Inter'] text-base font-normal leading-normal text-stone-900">
                as main e-commerce system.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex">
          <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center gap-2 rounded-md px-4 py-2"
            onClick={onBack}
          >
            <div className="relative flex h-4 w-4 items-center">
              <MoveLeft />
            </div>
          </div>
          <div
            className="flex cursor-pointer items-center justify-center gap-2 rounded-md border border-stone-200 px-4 py-2"
            onClick={handleCancel}
          >
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
              Cancel
            </div>
          </div>
        </div>
        <div className="flex w-24 items-center justify-center rounded-md">
          <Button
            onClick={onSubmit}
            className="w-full bg-black text-white"
            disabled={submittingInProgress}
          >
            {submittingInProgress ? 'Building PoC...' : 'Build PoC'}
          </Button>
        </div>
      </div>
    </div>
  )
}

function useCreateProject() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const navigate = useNavigate()

  /**
   * Create a new project.
   */
  const createFn = async ({
    organizationId,
    teamId,
    formData,
    usecaseFields,
    templateId,
    prompt,
  }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)

    try {
      // pick integer from 1 to 10 randomly
      const randomImage = Math.floor(Math.random() * 10) + 1
      // Payload should look something like this:
      const payload = {
        organizationId,
        teamId,
        projectData: {
          name: formData.name,
          description: formData.description,
          projectConfiguration: formData.projectConfiguration,
          image: `${randomImage}`,
          iterationDefaults: {
            environment: formData.environment.reduce((acc, { key, value, dontEncrypt }) => {
              if (value != null && value !== '') {
                acc[key] = { value, dontEncrypt }
              }
              return acc
            }, {}),
            repository: formData.repository.reduce((acc, { key, value }) => {
              if (key === 'githubKey' && value && value !== '') {
                acc[key] = { value, dontEncrypt: false }
              } else {
                acc[key] = value
              }

              return acc
            }, {}),
            usecase: usecaseFields.reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {}),
            templateId: templateId || null,
          },
        },
      }
      const response = await createProjectFirebaseFunction(payload)
      analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_CREATED, {
        organizationId,
        teamId,
        templateId,
        projectId: response?.projectId || 'N/A',
        projectName: formData.name,
      })
      const projectId = response.data.projectId
      const iterationResponse = await createIterationForProject({
        projectId,
        prompt,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration: formData.projectConfiguration,
      })
      setResponse(iterationResponse)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      navigate(
        `/projects/${iterationResponse.data.projectId}?iteration=${iterationResponse.data.iterationId}`
      )
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createFn, resetCreateFn }
}

function useCreateIteration() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new iteration for a project.
   * @param {Object} options - The options for the function.
   * @param {String} options.projectId - The ID of the project.
   * @param {String} options.prompt - The prompt for the iteration.

   * @returns {Promise<Object>} A promise that resolves when the iteration is created.
   */
  const createIteration = async ({
    projectId,
    prompt,
    sourceIterationId,
    configuration,
    usecase,
  }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const response = await createIterationForProject({
        projectId,
        prompt,
        sourceIterationId,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration,
        usecase,
      })
      setResponse(response)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateIteration() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createIterationFn: createIteration, resetCreateIteration }
}
